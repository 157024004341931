body {
  background-color: #f9f9fc;
  font-family: Open Sans, sans-serif;
  color: #212529;

  text-align: left;
}
::-moz-focus-inner {
  border: 0;
}

button,
input,
select,
textarea {
  outline: 0 !important;
  box-shadow: none !important;
}
select::-ms-expand {
  display: none;
}
input::-ms-input {
  background-color: red;
}
.checkin {
  padding: 40px 15px 10px;
}

.checkin input,
.checkin select {
  border-radius: 3px;
  background-color: #fff;
  height: 40px;
  display: block;
  width: 100%;
  font-size: 15px;
  margin-bottom: 15px;
  transition: all 0.2s;
  border: 1px solid #e3e3e3;
}

.checkin select {
  padding-left: 5px;
}

.checkin input:focus,
.checkin select:focus {
  border-color: #eb6a3a;
}

.checkin input {
  padding-left: 10px;
  padding-right: 10px;
}

.checkin .datepicker {
  position: relative;
}

.checkin .datepicker:after {
  content: "";
  display: inline-block;
  background-image: url("./images/calendar.svg");
  background-size: contain;
  background-position: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  opacity: 0.4;
}

.checkin .datepicker input {
  padding-right: 40px;
}

.checkin .label_check {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 30px;
  font-size: 15px;
}

.checkin .label_check input {
  margin: 0;
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.checkin .label_check span {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}

.checkin .label_check input:checked + span {
  border-color: #eb6a3a;
  background-image: url("./images/check.svg");
  background-size: 70%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.checkin .hours {
  justify-content: center;
}

.checkin p {
  font-size: 15px;
  margin-bottom: 15px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #eb6a3a;
}

.Dropdown-root {
  width: 50% !important;
}

.Dropdown-control {
  text-align: center;
}

.adjust {
  width: 100% !important;
}

.adjust > .Dropdown-control {
  text-align: center;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5d687e;
  width: 100%;
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 12px 8px;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  transition: all 0.2s;
}
select:focus {
  border-color: #eb6a3a;
}

.noBorders-noBackground {
  border: 0 !important;
  background-color: inherit !important;
}

*::-webkit-scrollbar {
  display: none !important;
}
.checkmark svg {
  color: #5d687e;
  font-size: 10px;
}
/* scroller */
body {
  scrollbar-width: none;
}
@media only screen and (min-width: 768px) {
  @-moz-document url-prefix() {
    html,
    body,
    #boxes {
      overflow-x: hidden !important;
    }
  }
}
.react-datepicker-popper {
  z-index: 999999999999999;
}
#test-id > .popup-content{
  top: -55px !important;
  left: 20px !important;
  width: 90% !important;
  height: 50px !important;
  border-radius: 10px;
}
.ReactModal__Overlay, .ReactModal__Overlay--after-open{
  z-index: 99 !important;
}